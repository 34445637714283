export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "footer": {
    "work": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Work"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])}
    },
    "foundation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Foundation"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
      "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Opportunities"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Info"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02-236049"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "adenagain.org"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aden, Khormakser, Security Administration Round, Philips building, 3d floor"])}
    },
    "rights": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["All rights reserved"]), _normalize(["Aden Again Cultural Foundation"])])},
    "venus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed with ❤️ in "])}
  },
  "home": {
    "hero": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeni <strong>youthful</strong> spirit born to bring back <strong>Aden's</strong> spark"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are we?"])}
    },
    "news": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest news"])}
    },
    "statistics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the foundation"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know More"])}
    },
    "projects": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Projects"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
    },
    "events": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Events"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
    }
  },
  "about": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Mission"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Goals"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Values"])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Projects"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
  },
  "project": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Statistics"])},
    "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Audience"])},
    "impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact"])},
    "outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outcomes"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Events"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])}
  },
  "events": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our events"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upcoming"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
  },
  "event": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event details"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other events"])}
  },
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest news"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
  },
  "article": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other news"])}
  },
  "jobs": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be part of the family"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Opportunities"])}
  },
  "job": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
  },
  "publications": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View our latest publications"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])}
  },
  "publication": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "gallery": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live the moment with us"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo gallery"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video gallery"])}
  },
  "album": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Album Description"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We love to hear from you"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any suggestion or complaint feel free to contact us!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent successfully!!"])}
  },
  "faker": {
    "post": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit."])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam aperiam consectetur, delectus dicta eaque eligendi eos error et hic illo ipsam ipsum molestiae nisi nostrum obcaecati odio omnis porro provident quibusdam sed sunt unde veniam! Ab amet aperiam blanditiis consectetur delectus, deserunt dolores eaque eveniet ipsa iusto nobis, odit porro quia quis sequi tempore voluptatibus? Aut, autem consequuntur, dolor esse est inventore ipsam magni minus molestias nam necessitatibus perspiciatis praesentium quaerat repellat rerum sint sit voluptatem. Ad alias, atque consequuntur dicta ducimus eos ex exercitationem fugiat iure laudantium mollitia nobis non obcaecati officiis perferendis possimus quibusdam repellendus tempore veritatis."])}
    },
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aspernatur beatae dolore dolorem est eveniet expedita id illum iure molestias natus odio, perferendis provident quasi, quidem reiciendis, ut vero voluptas! Consequuntur corporis cum deleniti distinctio dolorum ea fuga illum impedit incidunt laborum libero, nihil quibusdam, quod repellat repudiandae! Atque excepturi harum id illo incidunt quas quod sequi? Ab accusantium alias, assumenda deserunt, ex exercitationem illum minima nisi officiis pariatur sequi unde, voluptate voluptatum! Animi commodi consectetur cum debitis."])},
    "project": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam aperiam consectetur, delectus dicta eaque eligendi eos error et hic illo ipsam ipsum molestiae nisi nostrum obcaecati odio omnis porro provident quibusdam sed sunt unde veniam! Ab amet aperiam blanditiis consectetur delectus, deserunt dolores eaque eveniet ipsa iusto nobis, odit porro quia quis sequi tempore voluptatibus?"])}
    },
    "event": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upcoming"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aden Again Event"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam aperiam consectetur, delectus dicta eaque eligendi eos error et hic illo ipsam ipsum molestiae nisi nostrum obcaecati odio omnis porro provident quibusdam sed sunt unde veniam! Ab amet aperiam blanditiis consectetur delectus, deserunt dolores eaque eveniet ipsa iusto nobis, odit porro quia quis sequi tempore voluptatibus?"])}
    },
    "news": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launching a new project"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam aperiam consectetur, delectus dicta eaque eligendi eos error et hic illo ipsam ipsum molestiae nisi nostrum obcaecati odio omnis porro provident quibusdam sed sunt unde veniam! Ab amet aperiam blanditiis consectetur delectus, deserunt dolores eaque eveniet ipsa iusto nobis, odit porro quia quis sequi tempore voluptatibus?"])}
    },
    "publication": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Title"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam aperiam consectetur, delectus dicta eaque eligendi eos error et hic illo ipsam ipsum molestiae nisi nostrum obcaecati odio omnis porro provident quibusdam sed sunt unde veniam! Ab amet aperiam blanditiis consectetur delectus, deserunt dolores eaque eveniet ipsa iusto nobis, odit porro quia quis sequi tempore voluptatibus?"])}
    },
    "statistics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
      "first": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art gallery"])}
      },
      "second": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["453"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Workshop"])}
      },
      "third": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5640"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painting"])}
      }
    },
    "album": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Album title"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video title"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])}
    }
  }
}