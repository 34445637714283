import { defineStore } from 'pinia'

export const usePhotoModal = defineStore('photo-modal', {
  state: () => ({
    visible: false,
    index: 0,
  }),

  actions: {
    show(index) {
      this.index = index
      this.visible = true
      document.querySelector('html').classList.add('overflow-clip')
    },
    hide() {
      this.visible = false
      document.querySelector('html').classList.remove('overflow-clip')
    },
  },
})
