import { defineStore } from 'pinia'
import {
  getHome,
  getAboutUs,
  getProjects,
  getProject,
  getEvents,
  getEvent,
  getNews,
  getArticle,
  getJobs,
  getJob,
  getPublications,
  getPublication,
  getPhotoAlbums,
  getPhotoAlbum,
  getVideos,
  getVideo,
  postContactMessage,
} from '@/utils/apiClient'

export const useStore = defineStore('store', {
  state: () => ({
    home: [],
    aboutUs: null,
    projects: [],
    project: null,
    fixedProject: null,
    events: [],
    event: null,
    fixedEvent: null,
    news: [],
    article: null,
    fixedArticles: [],
    jobs: [],
    job: null,
    publications: [],
    publication: null,
    photoAlbums: [],
    photoAlbum: null,
    videos: [],
    video: null,
    page: '1',
    prev: null,
    next: null,
    isLoading: false,
    contactInfo: null,
  }),

  getters: {
    filteredProjects: (state) => {
      return state.projects.filter((proj) => {
        return proj.id !== state.fixedProject.id
      })
    },

    eventsFilterCurrent: (state) => {
      return state.events.filter((event) => {
        return event.id !== state.event.id
      })
    },

    newsFilterCurrent: (state) => {
      return state.news.filter((event) => {
        return event.id !== state.article.id
      })
    },
  },

  actions: {
    async fetchHomeData() {
      try {
        this.home = []
        let {
          data: { data },
        } = await getHome()
        this.home = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchAboutUsData() {
      try {
        this.aboutUs = null
        let {
          data: { data },
        } = await getAboutUs()
        this.aboutUs = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchProjects() {
      try {
        let {
          data: { data },
        } = await getProjects()
        this.projects = data
        this.fixedProject = this.projects[0]
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchProject(id) {
      try {
        this.project = null
        let {
          data: { data },
        } = await getProject(id)
        this.project = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchEvents(page) {
      this.page = page ? page : '1'
      this.isLoading = true
      try {
        let {
          data: { data, links },
        } = await getEvents(this.page)
        this.events = data
        if (this.page === '1') this.fixedEvent = this.events[0]
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
      this.isLoading = false
    },

    async fetchEvent(id) {
      try {
        this.event = null
        let {
          data: { data },
        } = await getEvent(id)
        this.event = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchNews(page) {
      this.page = page ? page : '1'
      this.isLoading = true
      try {
        this.news = []
        let {
          data: { data, links },
        } = await getNews(this.page)
        this.news = data
        if (this.page === '1') {
          this.fixedArticles = []
          this.fixedArticles.push(this.news[0])
          this.fixedArticles.push(this.news[1])
          this.fixedArticles.push(this.news[2])
        }
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
      this.isLoading = false
    },

    async fetchArticle(id) {
      try {
        this.article = null
        let {
          data: { data },
        } = await getArticle(id)
        this.article = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchJobs(page) {
      this.page = page ? page : '1'
      this.isLoading = true
      try {
        this.jobs = []
        let {
          data: { data, links },
        } = await getJobs(this.page)
        this.jobs = data
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
      this.isLoading = false
    },

    async fetchJob(id) {
      try {
        this.job = null
        let {
          data: { data },
        } = await getJob(id)
        this.job = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchPublications(page) {
      this.page = page ? page : '1'
      this.isLoading = true
      try {
        this.publications = []
        let {
          data: { data, links },
        } = await getPublications(this.page)
        this.publications = data
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
      this.isLoading = false
    },

    async fetchPublication(id) {
      try {
        this.publication = null
        let {
          data: { data },
        } = await getPublication(id)
        this.publication = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchPhotoAlbums(page) {
      this.page = page ? page : '1'
      this.isLoading = true
      try {
        this.photoAlbums = []
        let {
          data: { data, links },
        } = await getPhotoAlbums(this.page)
        this.photoAlbums = data
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
      this.isLoading = false
    },

    async fetchPhotoAlbum(id) {
      try {
        this.photoAlbum = null
        let {
          data: { data },
        } = await getPhotoAlbum(id)
        this.photoAlbum = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchVideos(page) {
      this.page = page ? page : '1'
      try {
        this.videos = []
        let {
          data: { data, links },
        } = await getVideos(this.page)
        this.videos = data
        this.prev = trimLink(links.prev)
        this.next = trimLink(links.next)
      } catch (error) {
        return Promise.reject()
      }
    },

    async fetchVideo(id) {
      try {
        this.video = null
        let {
          data: { data },
        } = await getVideo(id)
        this.video = data
      } catch (error) {
        return Promise.reject()
      }
    },

    async sendContactMessage(msg) {
      try {
        await postContactMessage(msg)
      } catch (error) {
        return Promise.reject()
      }
    },
  },
})

function trimLink(link) {
  if (link) {
    return /page=(.+)/.exec(link)[1]
  } else {
    return null
  }
}
