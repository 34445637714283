import { createRouter, createWebHistory } from 'vue-router'
import { routeMiddleware, getClientLocale } from '@/utils/Translation'
import NProgress from 'nprogress'
import Colors from '@/utils/Colors'
import { useStore } from '@/store'
import { useVideoModal } from '@/store/videoModal'
import { usePhotoModal } from '@/store/photoModal'

const routes = [
  {
    path: '/:locale',
    component: () => import('@/views/LayoutView'),
    beforeEnter: routeMiddleware,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Promise.all([Store.fetchHomeData(), Store.fetchAboutUsData()]).then(
            () => {
              next()
            }
          )
        },
        meta: {
          active: 'home',
          color: Colors.amber,
        },
      },
      {
        path: 'about-us',
        name: 'about',
        component: () => import('@/views/AboutView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchAboutUsData().then(() => {
            next()
          })
        },
        meta: {
          active: 'about',
          color: Colors.amber,
        },
      },
      {
        path: 'projects',
        name: 'projects',
        component: () => import('@/views/ProjectsView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchProjects().then(() => {
            next()
          })
        },
        meta: {
          active: 'projects',
          color: Colors.cyan,
        },
      },
      {
        path: 'projects/:id',
        name: 'project',
        component: () => import('@/views/ProjectView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchProject(to.params['id'])
            .then(() => {
              next()
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'projects',
          color: Colors.cyan,
        },
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('@/views/EventsView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchEvents().then(() => {
            next()
          })
        },
        meta: {
          active: 'events',
          color: Colors.violet,
        },
      },
      {
        path: 'events/:id',
        name: 'event',
        component: () => import('@/views/EventView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchEvent(to.params['id'])
            .then(() => {
              //TODO filter events remove current
              Store.fetchEvents().then(() => {
                next()
              })
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'events',
          color: Colors.violet,
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/NewsView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchNews().then(() => {
            next()
          })
        },
        meta: {
          active: 'news',
          color: Colors.red,
        },
      },
      {
        path: 'news/:id',
        name: 'article',
        component: () => import('@/views/ArticleView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchArticle(to.params['id'])
            .then(() => {
              Store.fetchNews().then(() => {
                next()
              })
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'news',
          color: Colors.red,
        },
      },
      {
        path: 'jobs',
        name: 'jobs',
        component: () => import('@/views/JobsView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchJobs().then(() => {
            let showJobs =
              document.querySelector('[data-is-job-exist]').dataset
                .isJobExist === 'true'
            if (showJobs) {
              next()
            } else {
              next('home')
            }
          })
        },
        meta: {
          active: 'jobs',
          color: Colors.amber,
        },
      },
      {
        path: 'jobs/:id',
        name: 'job',
        component: () => import('@/views/JobView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchJob(to.params['id'])
            .then(() => {
              let showJobs =
                document.querySelector('[data-is-job-exist]').dataset
                  .isJobExist === 'true'
              if (showJobs) {
                next()
              } else {
                next('home')
              }
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'jobs',
          color: Colors.amber,
        },
      },
      {
        path: 'publications',
        name: 'publications',
        component: () => import('@/views/PublicationsView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchPublications().then(() => {
            next()
          })
        },
        meta: {
          active: 'publications',
          color: Colors.amber,
        },
      },
      {
        path: 'publications/:id',
        name: 'publication',
        component: () => import('@/views/PublicationView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchPublication(to.params['id'])
            .then(() => {
              next()
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'publications',
          color: Colors.amber,
        },
      },
      {
        path: 'gallery',
        name: 'gallery',
        component: () => import('@/views/GalleryView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Promise.all([Store.fetchPhotoAlbums(), Store.fetchVideos()]).then(
            () => {
              next()
            }
          )
        },
        meta: {
          active: 'gallery',
          color: Colors.cyan,
        },
      },
      {
        path: 'gallery/:id',
        name: 'album',
        component: () => import('@/views/AlbumView'),
        beforeEnter: (to, from, next) => {
          const Store = useStore()
          Store.fetchPhotoAlbum(to.params['id'])
            .then(() => {
              next()
            })
            .catch(() => {
              //TODO redirect to 404
              return false
            })
        },
        meta: {
          active: 'gallery',
          color: Colors.amber,
        },
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/ContactView'),
        meta: {
          active: 'contact',
          color: Colors.violet,
        },
      },
    ],
  },
  {
    path: '/',
    redirect: getClientLocale,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const VideoModal = useVideoModal()
  const PhotoModal = usePhotoModal()
  VideoModal.hide()
  PhotoModal.hide()
  NProgress.configure({ showSpinner: false })
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
