import i18n from '@/plugins/i18n'
import router from '@/router'

const Translation = {
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(locale) {
    i18n.global.locale.value = locale
  },

  get supportedLocales() {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  },
}

function isLocaleSupported(locale) {
  return Translation.supportedLocales.includes(locale)
}

function getClientLocale() {
  return localStorage.getItem('locale') || Translation.defaultLocale
}

function setClientLocale(locale) {
  return localStorage.setItem('locale', locale)
}

// function loadLocaleFile(locale) {
//   return import(`@/locales/${locale}.json`)
// }

async function changeLocale(locale) {
  if (!isLocaleSupported(locale))
    return Promise.reject(new Error('Locale not supported'))

  setClientLocale(locale)

  document.querySelector('html').setAttribute('lang', locale)

  document
    .querySelector('html')
    .setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

  if (i18n.global.locale.value === locale) {
    return Promise.resolve(locale)
  }
  // await loadLocaleFile(locale).then((msgs) => {
  //   i18n.global.setLocaleMessage(locale, msgs.default)
  //
  //   Translation.currentLocale = locale || getClientLocale()
  //
  //   return locale
  // })
  Translation.currentLocale = locale || getClientLocale()
}

function switchLocale() {
  let newLocale = Translation.currentLocale === 'ar' ? 'en' : 'ar'
  changeLocale(newLocale).then(() => {
    const to = router.resolve({
      params: { locale: Translation.currentLocale },
    })
    router.push(to.fullPath).then(() => {
      location.reload()
    })
  })
}

function routeMiddleware(to, from, next) {
  const locale = to.params.locale
  if (!isLocaleSupported(locale)) {
    return next(getClientLocale())
  } else {
    return changeLocale(locale).then(() => next())
  }
}

export {
  changeLocale,
  getClientLocale,
  routeMiddleware,
  switchLocale,
  Translation,
}
