import axios from 'axios'
import { Translation } from '@/utils/Translation'

const apiClient = axios.create({
  baseURL: 'https://adenagain.org/api/',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const getHome = () => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/home`)
}

export const getAboutUs = () => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/about-us`)
}

export const getProjects = () => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/projects`)
}

export const getProject = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/projects/${id}`)
}

export const getEvents = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/events?page=${page}`)
}

export const getEvent = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/events/${id}`)
}

export const getNews = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/news?page=${page}`)
}

export const getArticle = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/news/${id}`)
}

export const getJobs = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/jobs?page=${page}`)
}

export const getJob = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/jobs/${id}`)
}

export const getPublications = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/publications?page=${page}`)
}

export const getPublication = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/publications/${id}`)
}

export const getPhotoAlbums = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/photo-gallery?page=${page}`)
}

export const getPhotoAlbum = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/photo-gallery/${id}`)
}

export const getVideos = (page) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/video-gallery?page=${page}`)
}

export const getVideo = (id) => {
  const locale = Translation.currentLocale
  return apiClient.get(`${locale}/video-gallery/${id}`)
}

export const postContactMessage = (msg) => {
  const locale = Translation.currentLocale
  return apiClient.post(`${locale}/contact-us`, msg)
}
