export default {
  amber: '#F0BB48',
  amberDark: '#E4A025',
  red: '#EB2E3A',
  redDark: '#D0252B',
  cyan: '#80CFCF',
  cyanDark: '#44ACB7',
  violet: '#634A9E',
  violetDark: '#503082',
  gray: {
    50: '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    800: '#27272a',
    900: '#18181b',
  },
}
