export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاريع"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخبار"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدارات"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])}
  },
  "footer": {
    "work": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمالنا"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاريع"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدارات"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])}
    },
    "foundation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤسسة"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الأخبار"])},
      "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف المتاحة"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التواصل"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02-236049"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "adenagain.org"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدن، خورمكسر، جولة إدارة الأمن، عمارة فيليبس، الدور الثالث"])}
    },
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
    "venus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طور بـ❤️ في "])}
  },
  "home": {
    "hero": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روح <strong>شـــبابية</strong> عـدنية نشــأت لتعيد <strong>لعدن</strong> بعض ألقهــــــــــا"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن؟"])}
    },
    "news": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الأخبار"])}
    },
    "statistics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المؤسسة"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعرف المزيد"])}
    },
    "projects": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاريعنا"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
    },
    "events": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الفعاليات"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركاؤنا"])}
    }
  },
  "about": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن؟"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتنا"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهدافنا"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمنا"])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاريعنا"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
  },
  "project": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات المشروع"])},
    "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهور المستهدف"])},
    "impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأثر"])},
    "outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخرجات"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المشروع"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعاليات المشروع"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الأخبار"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])}
  },
  "events": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعاليات عدن أجين"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية قادمة"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
  },
  "event": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الفعالية"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعاليات أخرى"])}
  },
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الأخبار"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
  },
  "article": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبار أخرى"])}
  },
  "jobs": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم للعائلة"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف المتاحة"])}
  },
  "job": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])}
  },
  "publications": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطّلع على آخر اصداراتنا"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدارات"])}
  },
  "publication": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])}
  },
  "gallery": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عش التجربة معنا"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرض الصور"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرض الفيديوهات"])}
  },
  "album": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصور"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الألبوم"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسعدنا السماع منك"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كان لديك أي مقترح أو شكوى لا تتردد في التواصل معنا!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرســــــــــــــــــــــــــــــال"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإرسال بنجاح!!"])}
  },
  "faker": {
    "post": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت، 2022 July 28"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص."])}
    },
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية.هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق."])},
    "project": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت, 2022 July 28"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص."])}
    },
    "event": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية قادمة"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية عدن أجين"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت, 2022 July 28"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص."])}
    },
    "news": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدشين مشروع جديد"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت, 2022 July 28"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGATHER"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص."])}
    },
    "publication": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الكتيب"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت, 2022 July 28"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية.هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق."])}
    },
    "statistics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات"])},
      "first": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرض فني"])}
      },
      "second": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["453"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورشة تدريبية"])}
      },
      "third": {
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5640"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة فنية"])}
      }
    },
    "album": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الأبوم"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت, 2022 July 28"])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video title"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday, 2022 July 28"])}
    }
  }
}